import type { PageProps } from 'gatsby'
import { graphql, Link as NavLink } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import type {
  BlogPostQuery,
  BlogPostQueryVariables,
} from '../../../graphql-types'
import Markdown from '../../components/Markdown'
import NewsletterSignUp from '../../components/NewsletterSignUp'
import Social from '../../components/Social'
import Preview from '../../components/blog/Preview'
import Grid from '../../components/blog/PreviewsGrid'
import { formatDate, getExcerpt } from '../../components/blog/utils'
import { Button } from '../../components/forms'
import Page from '../../components/layout/Page'
import { disc, maxScreenWidth } from '../../components/mixins'
import Container from '../../components/modules/Container'
import Hero from '../../components/modules/Hero'
import Img from '../../components/modules/Img'
import Spacer from '../../components/modules/Spacer'
import { Color } from '../../components/theme'
import { BodyRegular, CalloutSemibold } from '../../components/typography'

export type Query = BlogPostQuery
export type QueryVariables = BlogPostQueryVariables
export type Context = QueryVariables

export const query = graphql`
  query BlogPost($slug: String!) {
    post: contentfulBlogPost(slug: { eq: $slug }, node_locale: { eq: "en" }) {
      ...Post
    }
  }
`

const Header = styled.header`
  margin-top: 56px;
  text-align: center;
`
const Subtitle = styled(BodyRegular)`
  color: ${Color.GRAY_6};
`
const Title = styled(Hero)`
  margin-top: 24px;
`
const Author = styled.aside`
  img {
    ${disc(64)}
    margin-bottom: 16px;
  }
`
const Poster = styled(Img)`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  background: ${Color.GRAY_1};
  border-radius: 20px;
  display: block;

  ${maxScreenWidth(960)(css`
    border-radius: 0;
  `)}
`
const Article = styled(Markdown)`
  margin: 0 auto;
  max-width: 720px;
`

const BackButton = styled(NavLink)`
  margin: 0 auto;
  display: block;
  text-align: center;
`

const BlogPost = ({ data: { post }, uri: path }: PageProps<Query, Context>) => {
  const relatedPosts = post?.category?.posts
    ?.filter((p) => p?.slug !== post?.slug)
    .slice(0, 3)

  const publishedDateTime = `${post?.publishedOn}T12:00:00.000Z`

  return (
    <Page
      head={{
        title: `Blog - ${post?.title}`,
        description: post && getExcerpt(post),
        image: post?.poster?.file?.url,
        squareImage: post?.poster?.file?.url,
        openGraphType: 'article',
        metas: [
          {
            name: 'author',
            property: 'article:author',
            content: post?.author?.name,
          },
          {
            property: 'article:section',
            content: post?.category?.name,
          },
          {
            name: 'article-published_time',
            property: 'article:published_time',
            content: publishedDateTime,
          },
          {
            name: 'publish_date',
            property: 'og:publish_date',
            content: publishedDateTime,
          },
        ],
        children: (
          // Copied this from Medium
          <script type={'application/ld+json'}>
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'NewsArticle',
              image: [post?.poster?.file?.url],
              url: `https://withdouble.com/blog/${post?.slug}`,
              dateCreated: publishedDateTime,
              datePublished: publishedDateTime,
              dateModified: publishedDateTime,
              headline: post?.title,
              name: post?.title,
              description: post && getExcerpt(post),
              author: {
                '@type': 'Person',
                name: post?.author?.name,
              },
              creator: [post?.author?.name],
              publisher: {
                '@type': 'Organization',
                name: 'Double',
                url: 'https://withdouble.com',
              },
              mainEntityOfPage: `https://withdouble.com/blog/${post?.slug}`,
            })}
          </script>
        ),
      }}
    >
      <Header>
        <Subtitle>{formatDate(post?.publishedOn)}</Subtitle>
        <Title title={post?.title} />
        <Spacer size={'Small'} />
        <Author>
          <Img asset={post?.author?.avatar} />
          <CalloutSemibold>{post?.author?.name}</CalloutSemibold>
          <Subtitle>
            {post?.author?.position}
            {' at '}
            {post?.author?.company}
          </Subtitle>
        </Author>
      </Header>

      <Spacer size={'Small'} />
      <Poster asset={post?.poster} />
      <Spacer size={'Small'} />

      <Container>
        <Article markdown={post?.body?.body} />
      </Container>

      <Spacer size={'Small'} />

      <Social title={post?.title || ''} path={path} />

      <NewsletterSignUp />

      {relatedPosts?.length && (
        <Grid itemsCount={relatedPosts.length}>
          {relatedPosts.map(
            (relatedPost) =>
              relatedPost && (
                <Preview key={relatedPost.slug} post={relatedPost} noExcerpt />
              ),
          )}
        </Grid>
      )}

      <BackButton to={'/blog'}>
        <Button title={'Go back to blog'} isValid />
      </BackButton>

      <Spacer size={'Large'} />
    </Page>
  )
}

export default BlogPost
