import React from 'react'
import styled from 'styled-components'

import Facebook from './icons/Facebook.inline.svg'
import LinkedIn from './icons/LinkedIn.inline.svg'
import { Container } from './layout'

const Outer = styled(Container)`
  padding-bottom: 51px;

  // overwrite Container's default styling that switches to column on mobile
  flex-direction: row;
`

const Link = styled.a`
  margin: 0 14px;
`

interface SocialProps {
  title: string
  path: string
}

const Social = ({ path }: SocialProps): JSX.Element => {
  const encodedURL = encodeURIComponent(`https://withdouble.com${path}`)

  return (
    <Outer>
      <Link
        target={'_blank'}
        rel={'noopener noreferrer'}
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedURL}`}
        id={'blogshare-linkedin'}
      >
        <LinkedIn />
      </Link>
      <Link
        target={'_blank'}
        rel={'noopener noreferrer'}
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`}
        id={'blogshare-facebook'}
      >
        <Facebook />
      </Link>
    </Outer>
  )
}

export default Social
